import { BlogWrapper } from "../../../../src/collections/blog/Blog.style.js";
import { Link } from "gatsby";
import quickActionsPNG from "../../../../src/collections/blog/2024/11-05-what-is-the-kanvas-catalog/quick-actions-designs.png";
import importDesignButtonPNG from "../../../../src/collections/blog/2024/11-05-what-is-the-kanvas-catalog/import-button.png";
import importDesignPanelPNG from "../../../../src/collections/blog/2024/11-05-what-is-the-kanvas-catalog/import-designs-panel.png";
import renderedDesignPNG from "../../../../src/collections/blog/2024/11-05-what-is-the-kanvas-catalog/rendered-design.png";
import designPannelOpener from "../../../../src/collections/blog/2024/11-05-what-is-the-kanvas-catalog/designs-panel-opener.png";
import designerDockPNG from "../../../../src/collections/blog/2024/11-05-what-is-the-kanvas-catalog/designer-dock.png";
import catalogGIF from "../../../../src/collections/blog/2024/11-05-what-is-the-kanvas-catalog/catalog.gif";
import publishToCatalogGIF from "../../../../src/collections/blog/2024/11-05-what-is-the-kanvas-catalog/publish-to-catalog.gif";
import importGIF from "../../../../src/collections/blog/2024/11-05-what-is-the-kanvas-catalog/import-design-gif.gif";
import getStartedWithDesignerGIF from "../../../../src/collections/blog/2024/11-05-what-is-the-kanvas-catalog/start-from-scratch.gif";
import * as React from 'react';
export default {
  BlogWrapper,
  Link,
  quickActionsPNG,
  importDesignButtonPNG,
  importDesignPanelPNG,
  renderedDesignPNG,
  designPannelOpener,
  designerDockPNG,
  catalogGIF,
  publishToCatalogGIF,
  importGIF,
  getStartedWithDesignerGIF,
  React
};